<template>
  <div id="app">
    <div v-if="this.$root.normalPageStructure">
      <div class="box-page">
        <div class="d-md-flex">
          <sideMenu/>
          <div class="d-xl-none">
            <notificationMenu/>
            <favoritesMenu/>
          </div>
          <div class="box-content-column">
            <boxHeader/>
            <div class="box-page-content" :class="[ this.$root.headerFixed ? 'header-spacing' : '', this.$root.bigHeaderSpace ? 'big-header-spacing' : '', this.$root.hasSubHeader ? 'hasSubHeader' : '']" v-on:scroll.passive="handleScroll">
              <router-view :key="$route.fullPath" />
            </div>
            <boxFooter class="d-none d-xl-block"/>
            <boxBottomMenu class="d-xl-none"/>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <router-view :key="$route.fullPath" />
    </div>
  </div>
</template>

<script>
import sideMenu from "@/components/side-menu.vue";
import notificationMenu from "@/components/notification-menu.vue";
import favoritesMenu from "@/components/favorites-menu.vue";
import boxHeader from "@/components/header.vue";
import boxFooter from "@/components/footer.vue";
import boxBottomMenu from "@/components/box-bottom-menu.vue";

export default {
  name: 'BoxPT',
  metaInfo: {
    title: 'Page Title',
    titleTemplate: '%s | BOXPT',
    htmlAttrs: {
      lang:'',
    },
    meta: [
      { charset: 'utf-8' },
      { vmid: 'author', name: 'author', content: 'Webcomum - Digital Agency' },
      { vmid: 'description', name: 'description', content: 'description' },
      { vmid: 'keywords', name: 'keywords', content: 'keywords' },
    ]
  },
  data() {
    return {
    }
  },
  components: {
    sideMenu,
    notificationMenu,
    favoritesMenu,
    boxHeader,
    boxFooter,
    boxBottomMenu
  },
  methods: {
    handleScroll(e) {
      let scrolledValue = e.target.scrollTop;
      let header = document.getElementsByTagName("header")[0];
      let banner = document.querySelectorAll(".box-top-banner")[0];

      if ( header.classList.contains('fixed') && typeof(banner) != 'undefined' && banner != null ) {
        let bannerHeight = ( banner.clientHeight - 120 );
        let headerDesktop = document.querySelectorAll(".header-desktop")[0];

        if ( scrolledValue > bannerHeight ) {
          headerDesktop.classList.remove('transparent');
          headerDesktop.classList.remove('white-content');
          headerDesktop.classList.add('grey-bg');
        } else {
          headerDesktop.classList.remove('grey-bg');
          headerDesktop.classList.add('transparent');
          headerDesktop.classList.add('white-content');
        }
      }
    }
  }
};
</script>