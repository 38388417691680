<template>
  <footer>
    <div class="container">
      <div class="d-flex justify-content-center align-items-center">
        <p class="footer-copyright">©Copyright BOXPT. Todos os direitos Reservados. By. <a href="https://www.webcomum.com/" target="_blank">Webcomum</a></p>
      </div>
    </div>
  </footer>
</template>

<script>
  export default {
  name: "footer.vue"
  }
</script>