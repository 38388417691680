<template>
  <div>
    <div class="menu-workout-plans-wrapper">
      <div class="d-flex justify-content-between align-items-center d-xl-block">
        <div class="menu-workout-plans-heading">
          <p class="menu-workout-plans-title">Plano Premium</p>
          <p class="menu-workout-plans-subtitle">Desde 5,99€ /mês.*</p>
        </div>
        <router-link to="/reserved-area-plans" class="generic-btn extra-small white-border menu-workout-plans-btn">ver planos</router-link>
      </div>
    </div>
    <p class="menu-workout-plans-caption d-xl-none">* Pode cancelar a qualquer momento.</p>
  </div>
</template>

<script>
  export default {
    name: "workout-plans"
  }
</script>