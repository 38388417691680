import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueFormulate from '@braid/vue-formulate';
import yourErrorHandler from 'vue-errorhandler';
import "@/assets/scss/style.scss";
import '@fortawesome/fontawesome-free/css/all.min.css';
import '@fortawesome/fontawesome-free/js/all.min.js';
import VueSocialSharing from 'vue-social-sharing';
import axios from "axios";
import Meta from "vue-meta";
import VCalendar from 'v-calendar';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";

Vue.use(Toast)
Vue.use(VueSocialSharing);
Vue.use(VueFormulate);
Vue.use(VueFormulate, {
    errorHandler: yourErrorHandler
});
Vue.use(VueFormulate);
Vue.use(Meta, {
    keyName: 'metaInfo',
    attribute: 'data-vue-meta',
    ssrAttribute: 'data-vue-meta-server-rendered',
    tagIDKeyName: 'vmid',
    refreshOnceOnNavigation: true
})

Vue.use(require('vue-cookies'));

Vue.use(VCalendar);
Vue.config.productionTip = false;


const app = new Vue({
    router,
    data() {
        return {
            translations: [],
            errors: [],
            countries: [],
            userInfo: {
                photo: '@/assets/img/user.jpg',
            },
            siteLang: 'pt',
            slug: '',
            loggedIn: false,
            authKey: '',
            normalPageStructure: false,
            headerFixed: false,
            hasSubHeader: false,
            bigHeaderSpace: false,
            showCredits: false,
            hasSearch: false,
            hasHeaderTitle: false,
            reservedArea: false,
            showMenu: false,
            showMobileSearch: false,
            showNotifications: false,
            showFavorites: false,
            registerFormInfo: {},
            pageTitle: '',
            workoutByLinkActive: false,
            progressLinkActive: false,
            challengesLinkActive: false,
            settingsLinkActive: false,
            authorLinkActive: false,
            workoutBookmarks: [],
            authorBookmarks: [],
        };
    },
    watch: {
        '$route'() {
            if (typeof this.$route.meta.normalPageStructure !== 'undefined') {
                this.normalPageStructure = this.$route.meta.normalPageStructure
            } else {
                this.normalPageStructure = true
            }

            if (typeof this.$route.meta.headerFixed !== 'undefined') {
                this.headerFixed = this.$route.meta.headerFixed
            } else {
                this.headerFixed = false
            }

            if (typeof this.$route.meta.hasSubHeader !== 'undefined') {
                this.hasSubHeader = this.$route.meta.hasSubHeader
            } else {
                this.hasSubHeader = false
            }

            if (typeof this.$route.meta.bigHeaderSpace !== 'undefined') {
                this.bigHeaderSpace = this.$route.meta.bigHeaderSpace
            } else {
                this.bigHeaderSpace = false
            }

            if (typeof this.$route.meta.showCredits !== 'undefined') {
                this.showCredits = this.$route.meta.showCredits
            } else {
                this.showCredits = false
            }

            if (typeof this.$route.meta.hasSearch !== 'undefined') {
                this.hasSearch = this.$route.meta.hasSearch
            } else {
                this.hasSearch = false
            }

            /*if (this.$route.name === 'homepage') {
                this.hasHeaderTitle = false
            } else {
                this.hasHeaderTitle = true
            }*/

            if (typeof this.$route.meta.reservedArea !== 'undefined') {
                this.reservedArea = this.$route.meta.reservedArea
            } else {
                this.reservedArea = false
            }
        }
    },
    methods: {
        getLink: function (item) {
            return this.slugify(item.title) + '-' + item.id;
        },
        getSlug: function (item) {
            const slug = '#' + this.slugify(item.slug);
            return slug;
        },
        slugify: function (title) {
            let slug = '';
            // Change to lower case
            const titleLower = title.toLowerCase();
            // Letter "e"
            slug = titleLower.replace(/e|é|è|ẽ|ẻ|ẹ|ê|ế|ề|ễ|ể|ệ/gi, 'e');
            // Letter "a"
            slug = slug.replace(/a|á|à|ã|ả|ạ|ă|ắ|ằ|ẵ|ẳ|ặ|â|ấ|ầ|ẫ|ẩ|ậ/gi, 'a');
            // Letter "o"
            slug = slug.replace(/o|ó|ò|õ|ỏ|ọ|ô|ố|ồ|ỗ|ổ|ộ|ơ|ớ|ờ|ỡ|ở|ợ/gi, 'o');
            // Letter "u"
            slug = slug.replace(/u|ú|ù|ũ|ủ|ụ|ư|ứ|ừ|ữ|ử|ự/gi, 'u');
            // Letter "d"
            slug = slug.replace(/đ/gi, 'd');
            // Trim the last whitespace
            slug = slug.replace(/\s*$/g, '');
            // Change whitespace to "-"
            slug = slug.replace(/\s+/g, '-');
            return slug;
        },
        changeTab: function (item) {
            let itemID = item.id;

            let tabs = Array.from(document.querySelectorAll('.box-tab-item'));
            tabs.forEach(node => {
                let tabID = node.getAttribute("tabID");

                node.classList.remove("active");

                if (itemID == tabID) {
                    node.classList.add("active");
                }
            });

            let content = Array.from(document.querySelectorAll('.box-tab-content'));
            content.forEach(node => {
                let tabID = node.getAttribute("tabID");

                node.classList.remove("active");

                if (itemID == tabID) {
                    node.classList.add("active");
                }
            });

        },
        logoutHandler() {
            this.$root.loggedIn = false;
            this.$root.authKey = '';

            $cookies.remove("loggedIn");
            $cookies.remove("authKey");

            this.$router.push({path: '/'});
        },
        async getCustomer() {
            let authKey = window.$cookies.get("authKey");
            if (authKey != undefined) {
                await axios
                    .get(process.env.VUE_APP_API_URL + "customer", {
                        headers: {
                            Authorization: 'Bearer ' + authKey
                        }
                    })
                    .then((response) => {
                        this.$root.userInfo = response.data.model;

                        if (response.data.model.photo != null) {
                            this.$root.userInfo.photo = response.data.model.baseURL + response.data.model.photo;
                        }

                    })
                    .catch((e) => {
                        this.errors.push(e);

                    });
            }
        }
    },
    mounted() {
        this.siteLang = localStorage.lang ?? "pt";

        //CLICK OUTSIDE ELEMENT TO CLOSE
        document.addEventListener("mouseup", e => {
            if (!e.target.classList.contains('favorites-btn') && !e.target.classList.contains('favorite-menu-wrapper') && !e.target.closest('.favorite-menu-wrapper')) {
                let element = Array.from(document.querySelectorAll('.favorite-menu-wrapper'));

                element.forEach(node => {
                    if (this.showFavorites) {
                        node.classList.remove("open");
                        this.showFavorites = false;
                    }
                });
            }

            if (!e.target.classList.contains('notifications-btn') && !e.target.classList.contains('notification-menu-wrapper') && !e.target.closest('.notification-menu-wrapper')) {
                let element = Array.from(document.querySelectorAll('.notification-menu-wrapper'));

                element.forEach(node => {
                    if (this.showNotifications) {
                        node.classList.remove("open");
                        this.showNotifications = false;
                    }
                });
            }
        });

        this.getCustomer();

        let authKey = window.$cookies.get("authKey");

        if ( authKey != null && authKey != undefined ) {
            //workout bookmarks
            axios
                .get(process.env.VUE_APP_API_URL + 'bookmark?type=0', {
                    headers: {
                        Authorization: 'Bearer ' + authKey
                    }
                })
                .then((response) => {
                    this.workoutBookmarks = response.data.model;
                })
                .catch((e) => {
                    this.errors.push(e);
                }).finally((e) => {
            });

            //author bookmarks
            axios
                .get(process.env.VUE_APP_API_URL + 'bookmark?type=1', {
                    headers: {
                        Authorization: 'Bearer ' + authKey
                    }
                })
                .then((response) => {
                    this.authorBookmarks = response.data.model;
                })
                .catch((e) => {
                    this.errors.push(e);
                }).finally((e) => {
            });
        }
    },
    created() {
        //countries
        axios
            .get(process.env.VUE_APP_API_URL + "countries")
            .then((response) => {
                this.countries = response.data.model;

            })
            .catch((e) => {
                this.errors.push(e);
            });

        //translations
        axios
            .get(process.env.VUE_APP_API_URL + "translations?lang=" + this.siteLang)
            .then((response) => {
                this.translations = response.data;

            })
            .catch((e) => {
                this.errors.push(e);
            });
    },
    render: (h) => h(App),
}).$mount("#app");

router.beforeEach((to, from, next) => {
    app.showMenu = false;
    app.showMobileSearch = false;
    app.showNotifications = false;
    app.showFavorites = false;

    next();
});

router.beforeResolve((to, from, next) => {
    if ((to.name == 'register' && (Object.keys(app.registerFormInfo).length === 0 && app.registerFormInfo.constructor === Object))) {
        next('/');
    } else {
        next();
    }

    if (to.matched.some((record) => record.meta.requiresAuth)) { // <-- check for requiresAuth here and if logged in

        if (app.loggedIn || window.$cookies.get("loggedIn")) {
            next();
        } else {
            if (to.name == 'reset-password') {
                next('/reset-password');
            } else {
                next('/');
            }
        }
    } else {
        if (app.loggedIn || window.$cookies.get("loggedIn")) {
            next('/dashboard');
        } else {
            next();
        }
    }
});

router.afterEach((to, from, next) => {
    //setTimeout(() => app.showLoading = false, 200);
    //setTimeout(() => app.overflowRemove(), 300);
    if (to.meta != null) {
        if (to.meta.group === 'workout-by') {
            app.workoutByLinkActive = true;
        } else {
            app.workoutByLinkActive = false;
        }

        if (to.meta.group === 'progress') {
            app.progressLinkActive = true;
        } else {
            app.progressLinkActive = false;
        }

        if (to.meta.group === 'challenges') {
            app.challengesLinkActive = true;
        } else {
            app.challengesLinkActive = false;
        }

        if (to.meta.group === 'settings') {
            app.settingsLinkActive = true;
        } else {
            app.settingsLinkActive = false;
        }

        if (to.meta.group === 'author') {
            app.authorLinkActive = true;
        } else {
            app.authorLinkActive = false;
        }
    }

});


