<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
    <path fill="#777" d="M13.818 11.933l5.658-5.657c.52-.52.52-1.364 0-1.886-.522-.52-1.366-.52-1.886 0l-5.657 5.658L6.276 4.39c-.52-.52-1.364-.52-1.886 0-.52.522-.52 1.366 0 1.886l5.658 5.657L4.39 17.59c-.52.522-.52 1.366 0 1.886.262.26.602.39.943.39.341 0 .683-.13.943-.39l5.657-5.656 5.657 5.656c.26.26.602.39.943.39.341 0 .681-.13.943-.39.52-.52.52-1.364 0-1.886l-5.658-5.657z" transform="translate(-326 -273) translate(322 269)"/>
  </svg>
</template>

<script>
export default {
name: "close"
}
</script>