<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="18" viewBox="0 0 14 18">
    <path fill="#333" d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM8 9h8v10H8V9zm7.5-5l-1-1h-5l-1 1H5v2h14V4h-3.5z" transform="translate(-1563 -53) translate(1555 47) translate(3 3)"/>
  </svg>
</template>

<script>
export default {
name: "trash"
}
</script>