<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
    <path fill="#333" d="M11.515 9.944L16.23 5.23c.433-.434.433-1.137 0-1.571-.435-.434-1.138-.434-1.571 0L9.944 8.373 5.23 3.659c-.434-.434-1.137-.434-1.571 0-.434.434-.434 1.137 0 1.57l4.714 4.715L3.66 14.66c-.434.434-.434 1.137 0 1.57.217.218.5.326.785.326.285 0 .57-.108.786-.325l4.714-4.714 4.715 4.714c.216.217.5.325.785.325.285 0 .568-.108.786-.325.433-.434.433-1.137 0-1.571l-4.715-4.715z" transform="translate(-601 -481) translate(595 475) translate(5 5) rotate(-45 9.944 9.944)"/>
  </svg>
</template>

<script>
export default {
  name: 'plus'
}
</script>