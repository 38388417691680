<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
    <path fill="#222221" d="M17.5 7.5L16.25 5h-10v21.25h2.5V17.5H15l1.25 2.5H25V7.5h-7.5zm5 10h-5L16.25 15h-7.5V7.5H15l1.25 2.5h6.25v7.5z" transform="translate(-26 -570) translate(20 565)"/>
  </svg>
</template>

<script>
  export default {
    name: 'flag'
  }
</script>
