<template>
  <div class="favorite-menu-wrapper" :class="{open: this.$root.showFavorites }">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center favorite-menu-header">
        <button type="button" class="favorite-menu-close d-xl-none" @click="closefavoriteMenu">
          <chevronLeft/>
        </button>
        <p class="section-title">FAVORITOS</p>
        <div></div>
      </div>
      <div class="button-toggle-wrapper full-width">
        <div class="container d-flex justify-content-center align-items-center">
          <div class="button-toggle button-toggle-2-btns active-1">
            <button type="button" class="button-toggle-btn active" @click="buttonToggle" rel="workouts">Treinos</button>
            <button type="button" class="button-toggle-btn" @click="buttonToggle" rel="author">Criador</button>
            <!--<button type="button" class="button-toggle-btn" @click="buttonToggle" rel="trainer">Treinador</button>-->
          </div>
        </div>
      </div>
      <div class="favorite-menu-list active" rel="workouts">
        <div class="favorite-menu-item" v-for="(item,index) in this.$root.workoutBookmarks" :key="index">
          <router-link :to="{ name: 'workout-detail', params:{id:$root.getLink(item)}}" class="favorite-menu-item-link d-flex justify-content-between align-items-start">
            <div class="d-flex justify-content-start align-items-start">
              <div class="favorite-menu-item-text">
                <p class="favorite-menu-item-title">{{ item.title }}</p>
                <div class="workout-item-tags">
                  <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                    <p>{{ item.level }}</p>
                  </div>
                  <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                    <p>{{ item.duration }}</p>
                  </div>
                </div>
              </div>
            </div>
            <button type="button" class="favorite-menu-item-btn" @click="removeBookmark" :product-id="item.id">
              <trash/>
            </button>
          </router-link>
        </div>
      </div>
      <div class="favorite-menu-list" rel="author">
        <div class="favorite-menu-item" v-for="(item,index) in this.$root.authorBookmarks" :key="index">
          <router-link :to="{ name: 'author-detail', params:{id:$root.getLink(item)}}" class="favorite-menu-item-link d-flex justify-content-between align-items-start">
            <div class="d-flex justify-content-start align-items-start">
              <div class="favorite-menu-item-image-wrapper" v-if="!!item.photo">
                <div class="favorite-menu-item-image">
                  <img :src="$root.userInfo.baseURL + item.photo" alt="">
                </div>
              </div>
              <div class="favorite-menu-item-text">
                <p class="favorite-menu-item-title">{{ item.title }}</p>
                <div class="favorite-menu-item-subtitle-wrapper d-flex justify-content-start align-items-center">
                  <p class="favorite-menu-item-subtitle">{{ item.workouts }} Treinos</p>
                </div>
              </div>
            </div>
            <button type="button" class="favorite-menu-item-btn" @click="removeBookmark" :product-id="item.id">
              <trash/>
            </button>
          </router-link>
        </div>
      </div>
      <!--<div class="favorite-menu-list" rel="trainer">
        <div class="favorite-menu-item">
          <router-link to="/workout-detail" class="favorite-menu-item-link d-flex justify-content-between align-items-start">
            <div class="d-flex justify-content-start align-items-start">
              <div class="favorite-menu-item-image-wrapper">
                <div class="favorite-menu-item-image">
                  <img src="https://via.placeholder.com/30" alt="">
                </div>
              </div>
              <div class="favorite-menu-item-text">
                <p class="favorite-menu-item-title">Johanna Cunningham</p>
                <div class="favorite-menu-item-subtitle-wrapper d-flex justify-content-start align-items-center">
                  <p class="favorite-menu-item-subtitle">30 Treinos</p>
                </div>
              </div>
            </div>
            <button type="button" class="favorite-menu-item-btn">
              <trash/>
            </button>
          </router-link>
        </div>
        <div class="favorite-menu-item">
          <router-link to="/workout-detail" class="favorite-menu-item-link d-flex justify-content-between align-items-start">
            <div class="d-flex justify-content-start align-items-start">
              <div class="favorite-menu-item-image-wrapper">
                <div class="favorite-menu-item-image">
                  <img src="https://via.placeholder.com/30" alt="">
                </div>
              </div>
              <div class="favorite-menu-item-text">
                <p class="favorite-menu-item-title">Johanna Cunningham</p>
                <div class="favorite-menu-item-subtitle-wrapper d-flex justify-content-start align-items-center">
                  <p class="favorite-menu-item-subtitle">30 Treinos</p>
                </div>
              </div>
            </div>
            <button type="button" class="favorite-menu-item-btn">
              <trash/>
            </button>
          </router-link>
        </div>
        <div class="favorite-menu-item">
          <router-link to="/workout-detail" class="favorite-menu-item-link d-flex justify-content-between align-items-start">
            <div class="d-flex justify-content-start align-items-start">
              <div class="favorite-menu-item-image-wrapper">
                <div class="favorite-menu-item-image">
                  <img src="https://via.placeholder.com/30" alt="">
                </div>
              </div>
              <div class="favorite-menu-item-text">
                <p class="favorite-menu-item-title">Johanna Cunningham</p>
                <div class="favorite-menu-item-subtitle-wrapper d-flex justify-content-start align-items-center">
                  <p class="favorite-menu-item-subtitle">30 Treinos</p>
                </div>
              </div>
            </div>
            <button type="button" class="favorite-menu-item-btn">
              <trash/>
            </button>
          </router-link>
        </div>
        <div class="favorite-menu-item">
          <router-link to="/workout-detail" class="favorite-menu-item-link d-flex justify-content-between align-items-start">
            <div class="d-flex justify-content-start align-items-start">
              <div class="favorite-menu-item-image-wrapper">
                <div class="favorite-menu-item-image">
                  <img src="https://via.placeholder.com/30" alt="">
                </div>
              </div>
              <div class="favorite-menu-item-text">
                <p class="favorite-menu-item-title">Johanna Cunningham</p>
                <div class="favorite-menu-item-subtitle-wrapper d-flex justify-content-start align-items-center">
                  <p class="favorite-menu-item-subtitle">30 Treinos</p>
                </div>
              </div>
            </div>
            <button type="button" class="favorite-menu-item-btn">
              <trash/>
            </button>
          </router-link>
        </div>
        <div class="favorite-menu-item">
          <router-link to="/workout-detail" class="favorite-menu-item-link d-flex justify-content-between align-items-start">
            <div class="d-flex justify-content-start align-items-start">
              <div class="favorite-menu-item-image-wrapper">
                <div class="favorite-menu-item-image">
                  <img src="https://via.placeholder.com/30" alt="">
                </div>
              </div>
              <div class="favorite-menu-item-text">
                <p class="favorite-menu-item-title">Johanna Cunningham</p>
                <div class="favorite-menu-item-subtitle-wrapper d-flex justify-content-start align-items-center">
                  <p class="favorite-menu-item-subtitle">30 Treinos</p>
                </div>
              </div>
            </div>
            <button type="button" class="favorite-menu-item-btn">
              <trash/>
            </button>
          </router-link>
        </div>
        <div class="favorite-menu-item">
          <router-link to="/workout-detail" class="favorite-menu-item-link d-flex justify-content-between align-items-start">
            <div class="d-flex justify-content-start align-items-start">
              <div class="favorite-menu-item-image-wrapper">
                <div class="favorite-menu-item-image">
                  <img src="https://via.placeholder.com/30" alt="">
                </div>
              </div>
              <div class="favorite-menu-item-text">
                <p class="favorite-menu-item-title">Johanna Cunningham</p>
                <div class="favorite-menu-item-subtitle-wrapper d-flex justify-content-start align-items-center">
                  <p class="favorite-menu-item-subtitle">30 Treinos</p>
                </div>
              </div>
            </div>
            <button type="button" class="favorite-menu-item-btn">
              <trash/>
            </button>
          </router-link>
        </div>
      </div>-->
    </div>
  </div>
</template>

<script>
import chevronLeft from "@/components/svg/chevron-left.vue";
import trash from "@/components/svg/trash.vue";
import bell from "@/components/svg/bell.vue";
import weightIcon from "@/components/svg/weight-icon.vue";
import bodyIcon from "@/components/svg/body-icon-2.vue";
import trophyIcon from "@/components/svg/trophy-icon.vue";
import axios from "axios";
import qs from "qs";

export default {
  name: "favorite-menu",
  components: {
    chevronLeft,
    trash,
    bell,
    weightIcon,
    bodyIcon,
    trophyIcon
  },
  methods: {
    closefavoriteMenu() {
      let element = Array.from(document.querySelectorAll('.favorite-menu-wrapper'));
      //let html = document.getElementsByTagName("html")[0];
      //let body = document.getElementsByTagName("body")[0];

      element.forEach(node => {
        if ( node.classList.contains("open") ) {
          //let style = window.getComputedStyle(body);
          //let scrolledValue = parseInt(style.getPropertyValue('top').slice(0, -2)) * -1;

          node.classList.remove("open");
          //html.classList.remove("no-scroll");

          //body.style.top = "auto";

          //window.scrollTo(0, scrolledValue);
        }
      });
    },
    buttonToggle(e) {
      let element = e.currentTarget;
      let elementIndex = Array.from(element.parentElement.children).indexOf(element) + 1;
      let elementRel = element.getAttribute('rel');

      let parent = element.closest('.button-toggle');

      let buttons = Array.from(parent.children);
      let contents = Array.from(document.querySelectorAll('.favorite-menu-list:not([rel="'+ elementRel +'"])'));
      let selectedContent = Array.from(document.querySelectorAll('.favorite-menu-list[rel="'+ elementRel +'"]'));

      if ( ! element.classList.contains('active') ) {
        buttons.forEach(node => {
          node.classList.remove('active');
        });

        contents.forEach(node => {
          node.classList.remove('active');
        });

        selectedContent.forEach(node => {
          node.classList.add('active');
        });

        parent.classList.remove('active-1');
        parent.classList.remove('active-2');
        parent.classList.remove('active-3');
        parent.classList.add('active-' + elementIndex);

        element.classList.add('active');
      }
    },
    removeBookmark(e) {
      e.preventDefault();
      let authKey = window.$cookies.get("authKey");
      let productId = e.target.getAttribute("product-id");

      const requestBody = {
        bookmark: productId,
      };

      axios
          .post(process.env.VUE_APP_API_URL + 'bookmark', qs.stringify(requestBody),{
            headers: {
              Authorization: 'Bearer ' + authKey
            }
          })
          .then((response) => {
            if (response.data.success) {
              //e.target.closest('.favorite-menu-item').remove();
            }
          })
          .catch((e) => {
            this.errors.push(e);
            this.showMessage = true;
            this.formResponseMessage = 'An error occurred.';
          });
    },
  }
}
</script>