<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="23" viewBox="0 0 24 23">
    <path fill="#222221" d="M12.613 19.488l1.762 1.762 6.25-6.25-6.25-6.25-1.762 1.762 3.225 3.238H3.75v2.5h12.088l-3.225 3.238zM23.75 3.75H6.25c-1.388 0-2.5 1.125-2.5 2.5v5h2.5v-5h17.5v17.5H6.25v-5h-2.5v5c0 1.375 1.112 2.5 2.5 2.5h17.5c1.375 0 2.5-1.125 2.5-2.5V6.25c0-1.375-1.125-2.5-2.5-2.5z" transform="translate(-1855 -50) translate(335 -.32) translate(1260 26) translate(256.944 20.82)"/>
  </svg>
</template>

<script>
export default {
  name: 'logout'
}
</script>