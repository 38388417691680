<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
    <path fill="#222221" d="M22.16 15.975c.037-.313.062-.637.062-.975 0-.338-.025-.662-.075-.975l2.112-1.65c.188-.15.238-.425.125-.637l-2-3.463c-.125-.225-.387-.3-.612-.225l-2.488 1c-.525-.4-1.075-.725-1.687-.975l-.375-2.65c-.038-.25-.25-.425-.5-.425h-4c-.25 0-.45.175-.488.425l-.375 2.65c-.612.25-1.175.588-1.687.975l-2.488-1c-.225-.088-.487 0-.612.225l-2 3.463c-.125.225-.075.487.125.637l2.112 1.65c-.05.313-.087.65-.087.975 0 .325.025.662.075.975l-2.113 1.65c-.187.15-.237.425-.125.637l2 3.463c.125.225.388.3.613.225l2.487-1c.525.4 1.075.725 1.688.975l.375 2.65c.05.25.25.425.5.425h4c.25 0 .462-.175.487-.425l.375-2.65c.613-.25 1.175-.588 1.688-.975l2.487 1c.225.088.488 0 .613-.225l2-3.463c.125-.224.075-.487-.125-.637l-2.088-1.65zm-7.438 2.775c-2.063 0-3.75-1.688-3.75-3.75 0-2.063 1.687-3.75 3.75-3.75 2.062 0 3.75 1.688 3.75 3.75 0 2.063-1.688 3.75-3.75 3.75z" transform="translate(-25 -630) translate(20 625)"/>
  </svg>
</template>

<script>
  export default {
    name: 'settings-icon'
  }
</script>
