<template>
  <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
  viewBox="0 0 20 26" style="enable-background:new 0 0 20 26;" xml:space="preserve">
    <path class="st0" style="fill-rule:evenodd;clip-rule:evenodd;fill:#222221;" d="M18.7,25.2c-0.3,0-0.5-0.1-0.8-0.2L10,19.7L2.1,25c-0.4,0.3-0.9,0.3-1.4,0.1C0.3,24.8,0,24.4,0,23.9V1.4
    c0-0.7,0.6-1.2,1.3-1.2h17.4c0.7,0,1.3,0.6,1.3,1.2v22.5c0,0.5-0.3,0.9-0.7,1.1C19.1,25.1,18.9,25.2,18.7,25.2"/>
    <g>
      <path class="st1" style="fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;" d="M10,16.9c0.3,0,0.5,0.1,0.8,0.2l6.6,4.4V2.7H2.6v18.8l6.6-4.4C9.5,17,9.7,16.9,10,16.9"/>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'bookmark'
}
</script>
