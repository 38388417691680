<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="26" viewBox="0 0 24 26">
    <path fill="#222221" d="M20 2.5c1.38 0 2.5 1.12 2.5 2.5v17.5c0 1.38-1.12 2.5-2.5 2.5H2.5C1.12 25 0 23.88 0 22.5V5c0-1.38 1.12-2.5 2.5-2.5H20zM20 5H2.5v17.5H20V5zm-9 12.5h.5c.552 0 1 .448 1 1v1.75c0 .552-.448 1-1 1H11c-.552 0-1-.448-1-1V18.5c0-.552.448-1 1-1zm6.25 0h.5c.552 0 1 .448 1 1v1.75c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1V18.5c0-.552.448-1 1-1zm-12.5 0h.5c.552 0 1 .448 1 1v1.75c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1V18.5c0-.552.448-1 1-1zm0-5h.5c.552 0 1 .448 1 1v1.75c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1V13.5c0-.552.448-1 1-1zm6.25 0h.5c.552 0 1 .448 1 1v1.75c0 .552-.448 1-1 1H11c-.552 0-1-.448-1-1V13.5c0-.552.448-1 1-1zm6.25 0h.5c.552 0 1 .448 1 1v1.75c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1V13.5c0-.552.448-1 1-1zm0-5h.5c.552 0 1 .448 1 1v1.75c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1V8.5c0-.552.448-1 1-1zM11 7.5h.5c.552 0 1 .448 1 1v1.75c0 .552-.448 1-1 1H11c-.552 0-1-.448-1-1V8.5c0-.552.448-1 1-1zm-6.25 0h.5c.552 0 1 .448 1 1v1.75c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1V8.5c0-.552.448-1 1-1z" transform="translate(-23 -446) translate(20 445) translate(3.75 1.25)"/>
    <path fill="#222221" d="M17.75 0c.552 0 1 .448 1 1v4.25c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1h.5zM5.25 0c.552 0 1 .448 1 1v4.25c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1h.5zm6.25 0c.552 0 1 .448 1 1v4.25c0 .552-.448 1-1 1H11c-.552 0-1-.448-1-1V1c0-.552.448-1 1-1h.5z" transform="translate(-23 -446) translate(20 445) translate(3.75 1.25)"/>
  </svg>
</template>

<script>
  export default {
    name: 'calendar'
  }
</script>
