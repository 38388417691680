import Vue from "vue";
import VueRouter from "vue-router";
import VueLazyload from "vue-lazyload";

Vue.use(VueRouter);
Vue.use(VueLazyload);

function lazyLoad(view) {
    return () => import(`../views/${view}.vue`)
}

const routes = [
    {
        path: "/",
        name: "authentication",
        component: lazyLoad('auth/index'),
        meta:{
            normalPageStructure: false
        }
    },
    {
        path: "/register",
        name: "register",
        component: lazyLoad('auth/register'),
        meta:{
            normalPageStructure: false
        }
    },
    {
        path: "/reset-password",
        name: "reset-password",
        component: lazyLoad('auth/reset-password'),
        meta:{
            normalPageStructure: false
        }

    },
    {
        path: "/dashboard",
        name: "dashboard",
        component: lazyLoad('index'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/workout-search/:search",
        name: "workout-search",
        component: lazyLoad('workout/workout-search'),
        meta: {
            requiresAuth: true,
            hasSearch: true
        },

    },
    {
        path: "/workout-list",
        name: "workout-list",
        component: lazyLoad('workout/workout-list'),
        meta: {
            requiresAuth: true,
            headerFixed: true,
            hasSubHeader: true,
            bigHeaderSpace :true,
            hasSearch: true
        },

    },
    {
        path: "/workout-by-index",
        name: "workout-by-index",
        component: lazyLoad('workout/workout-by-index'),
        meta: {
            requiresAuth: true,
            hasSubHeader: true,
            hasSearch: true
        },

    },
    {
        path: "/workout-by-equipment",
        name: "workout-by-equipment",
        component: lazyLoad('workout/workout-by-equipment'),
        meta: {
            group: 'workout-by',
            requiresAuth: true,
        },
    },
    {
        path: "/workout-by-sport",
        name: "workout-by-sport",
        component: lazyLoad('workout/workout-by-sport'),
        meta: {
            group: 'workout-by',
            requiresAuth: true,
        },
    },
    {
        path: "/workout-by-muscle",
        name: "workout-by-muscle",
        component: lazyLoad('workout/workout-by-muscle'),
        meta: {
            group: 'workout-by',
            requiresAuth: true,
        },
    },
    {
        path: "/workout-by-list",
        name: "workout-by-list",
        component: lazyLoad('workout/workout-by-list'),
        meta: {
            group: 'workout-by',
            requiresAuth: true,
            hasSubHeader: true,
            hasSearch: true
        },

    },
    {
        path: "/workout-by-list-category",
        name: "workout-by-list-category",
        component: lazyLoad('workout/workout-by-list-category'),
        meta: {
            group: 'workout-by',
            requiresAuth: true,
            hasSubHeader: true,
            hasSearch: true
        },

    },
    {
        path: "/workout-by-list-subcategory",
        name: "workout-by-list-subcategory",
        component: lazyLoad('workout/workout-by-list-subcategory'),
        meta: {
            group: 'workout-by',
            requiresAuth: true,
            hasSubHeader: true,
            hasSearch: true
        },

    },
    {
        path: "/workout-by-sublist",
        name: "workout-by-sublist",
        component: lazyLoad('workout/workout-by-sublist'),
        meta: {
            group: 'workout-by',
            requiresAuth: true,
            hasSubHeader: true,
            hasSearch: true
        },

    },
    {
        path: "/workout-detail/:id",
        name: "workout-detail",
        component: lazyLoad('workout/workout-detail'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/workshop-list",
        name: "workshop-list",
        component: lazyLoad('workshop/workshop-list'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/workshop-detail/:id",
        name: "workshop-detail",
        component: lazyLoad('workshop/workshop-detail'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/workout-pt-schedule",
        name: "workout-pt-schedule",
        component: lazyLoad('workout/workout-pt-schedule'),
        meta: {
            requiresAuth: true,
            hasSubHeader: true,
            showCredits: true,
            hasSearch: true
        },

    },
    {
        path: "/workout-pt-appointment",
        name: "workout-pt-appointment",
        component: lazyLoad('workout/workout-pt-appointment'),
        meta: {
            requiresAuth: true,
            hasSubHeader: true
        }
    },
    {
        path: "/workout-pt-detail/:id",
        name: "workout-pt-detail",
        component: lazyLoad('workout/workout-pt-detail'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/author-list",
        name: "author-list",
        component: lazyLoad('author/author-list'),
        meta: {
            group: 'author',
            requiresAuth: true,
            hasSubHeader: true,
            hasSearch: true
        }
    },
    {
        path: "/author-detail/:id",
        name: "author-detail",
        component: lazyLoad('author/author-detail'),
        meta: {
            group: 'author',
            requiresAuth: true,
        },
    },
    {
        path: "/coach-detail/:id",
        name: "coach-detail",
        component: lazyLoad('coach/coach-detail'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/library",
        name: "library",
        component: lazyLoad('library/library-list'),
        meta: {
            requiresAuth: true,
            hasSubHeader: true,
            hasSearch: true
        },

    },
    {
        path: "/exercise-detail/:id",
        name: "exercise-detail",
        component: lazyLoad('exercise/exercise-detail'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/calendar-list",
        name: "calendar-list",
        component: lazyLoad('calendar/calendar-list'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/progress-achievements",
        name: "progress-achievements",
        component: lazyLoad('progress/progress-achievements'),
        meta: {
            group: 'progress',
            requiresAuth: true,
        },
    },
    {
        path: "/progress-history",
        name: "progress-history",
        component: lazyLoad('progress/progress-history'),
        meta: {
            group: 'progress',
            requiresAuth: true,
        },
    },
    {
        path: "/challenges-list",
        name: "challenges-list",
        component: lazyLoad('challenges/challenges-list'),
        meta: {
            group: 'challenges',
            requiresAuth: true,
        },
    },
    {
        path: "/challenges-detail/:id",
        name: "challenges-detail",
        component: lazyLoad('challenges/challenges-detail'),
        meta: {
            group: 'challenges',
            requiresAuth: true,
        },
    },
    {
        path: "/reserved-area-personal-info",
        name: "reserved-area-personal-info",
        component: lazyLoad('reserved-area/personal-info'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/reserved-area-change-password",
        name: "reserved-area-change-password",
        component: lazyLoad('reserved-area/change-password'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/reserved-area-billing-data",
        name: "reserved-area-billing-data",
        component: lazyLoad('reserved-area/billing-data'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/reserved-area-plans",
        name: "reserved-area-plans",
        component: lazyLoad('reserved-area/plans'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/reserved-area-notifications",
        name: "reserved-area-notifications",
        component: lazyLoad('reserved-area/notifications'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/reserved-area-contacts",
        name: "reserved-area-contacts",
        component: lazyLoad('reserved-area/contacts'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/reserved-area-policy",
        name: "reserved-area-policy",
        component: lazyLoad('reserved-area/policy'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/reserved-area-terms",
        name: "reserved-area-terms",
        component: lazyLoad('reserved-area/terms'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/reserved-area-about",
        name: "reserved-area-about",
        component: lazyLoad('reserved-area/about'),
        meta: {
            group: 'settings',
            requiresAuth: true,
        },
    },
    {
        path: "/reserved-area-faqs",
        name: "reserved-area-faqs",
        component: lazyLoad('reserved-area/faqs'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/reserved-area-invoices",
        name: "reserved-area-invoices",
        component: lazyLoad('reserved-area/invoices'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/reserved-area-credits",
        name: "reserved-area-credits",
        component: lazyLoad('reserved-area/credits'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/ask-role",
        name: "reserved-area-creator-info",
        component: lazyLoad('reserved-area/creator/creator-info'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/reserved-area-creator-workouts",
        name: "reserved-area-creator-workouts",
        component: lazyLoad('reserved-area/creator/creator-workouts'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/reserved-area-creator-add-exercises",
        name: "reserved-area-creator-add-exercises",
        component: lazyLoad('reserved-area/creator/add-exercises'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/reserved-area-creator-personal-page",
        name: "reserved-area-creator-personal-page",
        component: lazyLoad('reserved-area/creator/creator-personal-page'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/reserved-area-creator-add-workout",
        name: "reserved-area-creator-add-workout",
        component: lazyLoad('reserved-area/creator/add-workout'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/reserved-area-creator-calendar",
        name: "reserved-area-creator-calendar",
        component: lazyLoad('reserved-area/creator/calendar'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/reserved-area-creator-calendar-out",
        name: "reserved-area-creator-calendar-out",
        component: lazyLoad('reserved-area/creator/calendar-out'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/reserved-area-creator-calendar-add",
        name: "reserved-area-creator-calendar-add",
        component: lazyLoad('reserved-area/creator/calendar-add'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/reserved-area-creator-dashboard",
        name: "reserved-area-creator-dashboard",
        component: lazyLoad('reserved-area/creator/dashboard'),
        meta: {
            group: 'settings',
            requiresAuth: true,
            hasSubHeader: true,
            reservedArea:true
        },

    },
    {
        path: "/error",
        name: "error",
        component: lazyLoad('error'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/success",
        name: "success",
        component: lazyLoad('success'),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/not-found",
        name: "not-found",
        component: lazyLoad('not-found'),
        meta: {
            requiresAuth: true,
        },
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: "active",
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            let element = document.getElementsByClassName("box-page-content")[0];

            if (typeof (element) != 'undefined' && element != null) {
                element.scrollTop = 0
            }

            return {x: 0, y: 0}
        }
    }
});


export default router;
