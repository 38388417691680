<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="15" viewBox="0 0 16 15">
    <path fill="#00" d="M19 17.57c.552 0 1 .448 1 1s-.448 1-1 1H5c-.552 0-1-.448-1-1s.448-1 1-1h14zm0-6.44c.552 0 1 .448 1 1s-.448 1-1 1H5c-.552 0-1-.448-1-1s.448-1 1-1h14zM19 5c.552 0 1 .448 1 1s-.448 1-1 1H5c-.552 0-1-.448-1-1s.448-1 1-1h14z" transform="translate(-24 -61) translate(0 44) translate(20 12)"/>
  </svg>
</template>

<script>
export default {
  name: 'menu-icon'
}
</script>
