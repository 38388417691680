<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="7" viewBox="0 0 16 7">
    <path fill="#222221" d="M9.999 19.177c-.199 0-.4-.059-.575-.183-.451-.317-.56-.942-.241-1.393l4.236-6.012-4.236-6.013c-.319-.451-.21-1.075.241-1.393.453-.319 1.075-.21 1.393.241l4.643 6.588c.244.346.244.807 0 1.153l-4.643 6.588c-.194.277-.504.424-.818.424" transform="translate(-924 -556) translate(920 548) rotate(90 12.322 11.589)"/>
  </svg>
</template>

<script>
export default {
  name: 'chevron-down'
}
</script>