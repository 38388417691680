<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="50" height="48" viewBox="0 0 50 48">
    <path fill="#A01E1D" d="M39.115 12.269v-7.14c.11.02.213.065.33.065h6.324c-1.593 3.176-3.836 5.529-6.654 7.075zm-8.453 10.777l-5.793-3.499-5.532 3.499 1.535-6.593-5.035-4.18 6.744-.575 2.418-6.08 2.633 6.236 6.53.42-5.116 4.43 1.616 6.342zM4.232 5.194h6.323c.116 0 .219-.045.33-.068v7.143c-2.816-1.546-5.06-3.9-6.653-7.075zm45.466-2.638c-.312-.45-.827-.72-1.378-.72h-8.876c-.116 0-.219.044-.329.065V0h-28.23v1.903c-.111-.023-.214-.068-.33-.068H1.679c-.55 0-1.064.27-1.379.721-.312.45-.387 1.026-.195 1.541 1.972 5.33 5.235 9.175 9.692 11.432.242.124.501.182.755.182.117 0 .22-.067.334-.09v6.154c0 6.39 5.475 11.695 12.458 12.432-.003.04-.024.072-.024.112v8.817H12.112c-.925 0-1.679.754-1.679 1.68 0 .928.754 1.679 1.68 1.679h25.773c.929 0 1.68-.751 1.68-1.68 0-.925-.751-1.679-1.68-1.679H26.678V34.32c0-.04-.018-.072-.02-.112 6.982-.737 12.457-6.05 12.457-12.432V15.62c.112.024.215.091.332.091.254 0 .513-.058.755-.182 4.46-2.255 7.72-6.103 9.695-11.432.189-.515.116-1.091-.199-1.541z" transform="translate(-1054 -928) translate(1054 928.682)"/>
  </svg>
</template>

<script>
export default {
name: "trophy-icon"
}
</script>