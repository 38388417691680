<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="7" height="16" viewBox="0 0 7 16">
    <path fill="#333" d="M9.424 18.994c-.45-.317-.56-.942-.24-1.393l4.235-6.012-4.236-6.013c-.319-.45-.21-1.075.241-1.393.453-.319 1.075-.21 1.393.241l4.643 6.588c.244.346.244.807 0 1.153l-4.643 6.588c-.194.277-.504.424-.818.424-.199 0-.4-.059-.575-.183z" transform="translate(-32 -61) translate(20 54) translate(3 3) rotate(-180 12.322 11.589)"/>
  </svg>
</template>

<script>
export default {
  name: 'chevron-left'
}
</script>
