<template>
  <div class="side-menu" :class="{open: this.$root.showMenu }">
      <div class="side-menu-flex d-lg-flex flex-column justify-content-between">
        <div>
          <div class="menu-top d-none d-xl-block">
            <div class="container">
              <div class="d-flex justify-content-center align-items-center">
                <router-link to="/dashboard" class="menu-top-logo">
                  <LogoBoxSVG/>
                </router-link>
              </div>
            </div>
          </div>
          <div class="user-presentation-wrapper d-xl-none">
            <div class="container">
              <div class="user-presentation d-flex justify-content-start align-items-start">
                <div class="user-presentation-img">
                  <img :src="this.$root.userInfo.photo ? this.$root.userInfo.photo : require('@/assets/img/user.jpg')" alt="">
                </div>
                <div class="user-presentation-content">
                  <p class="user-presentation-title">Olá {{ this.$root.userInfo.name }}!</p>
                  <div class="user-presentation-info d-flex justify-content-start align-items-center">
                    <p>Plano Grátis</p>
                    <p><span>0</span> Créditos</p>
                  </div>
                  <button type="button" class="user-presentation-logout" @click="this.$root.logoutHandler">Terminar Sessão</button>
                </div>
              </div>
            </div>
          </div>
          <nav class="side-menu-menu">
            <div class="container" v-if="!!menuItems">
              <div class="side-menu-faqs-item" v-if="!!menuItems[0]">
                <button type="button" class="side-menu-faqs-btn d-flex justify-content-between align-items-center" @click="toggleSideMenu">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="side-menu-faqs-btn-image">
                      <workout/>
                    </div>
                    <p>{{ menuItems[0].title }}</p>
                  </div>
                  <div class="side-menu-faqs-btn-icon">
                    <chevronDown/>
                  </div>
                </button>
                <div class="side-menu-faqs-content">
                  <ul class="side-submenu">
                    <li>
                      <!-- link can have class active -->
                      <router-link :to="{ name: 'workout-list', params:{category:menuItems[0].sub[0].slug}}">{{ menuItems[0].sub[0].title }}</router-link>
                      <ul>
                        <li>
                          <router-link :to="{ name: 'workout-by-equipment', params:{category:menuItems[0].sub[1].slug}}" :class="[ this.$root.workoutByLinkActive ? 'active' : '' ]">{{ menuItems[0].sub[1].title }}</router-link>
                        </li>
                        <li>
                          <router-link :to="{ name: 'author-list', params:{category:menuItems[0].sub[2].slug}}" :class="[ this.$root.authorLinkActive ? 'active' : '' ]">{{ menuItems[0].sub[2].title }}</router-link>
                        </li>
                        <!--<li>
                          <router-link :to="{ name: 'workout-pt-schedule', params:{category:menuItems[0].sub[3].slug}}">{{ menuItems[0].sub[3].title }}</router-link>
                        </li>-->
                      </ul>
                    </li>
                    <li>
                      <router-link :to="{ name: 'library', params:{category:menuItems[0].sub[4].slug}}">{{ menuItems[0].sub[4].title }}</router-link>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="side-menu-faqs-item" v-if="!!menuItems[1]">
                <router-link :to="{ name: 'calendar-list', params:{category:menuItems[1].slug}}" class="side-menu-faqs-btn d-flex justify-content-between align-items-center">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="side-menu-faqs-btn-image">
                      <calendar/>
                    </div>
                    <p>{{ menuItems[1].title }}</p>
                  </div>
                </router-link>
              </div>
              <div class="side-menu-faqs-item" v-if="!!menuItems[2]">
                <router-link :to="{ name: 'progress-achievements', params:{category:menuItems[2].slug}}" class="side-menu-faqs-btn d-flex justify-content-between align-items-center" :class="[ this.$root.progressLinkActive ? 'active' : '' ]">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="side-menu-faqs-btn-image">
                      <progressIcon/>
                    </div>
                    <p>{{ menuItems[2].title }}</p>
                  </div>
                </router-link>
              </div>
              <div class="side-menu-faqs-item" v-if="!!menuItems[3]">
                <router-link :to="{ name: 'challenges-list', params:{category:menuItems[3].slug}}" class="side-menu-faqs-btn d-flex justify-content-between align-items-center" :class="[ this.$root.challengesLinkActive ? 'active' : '' ]">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="side-menu-faqs-btn-image">
                      <flag/>
                    </div>
                    <p>{{ menuItems[3].title }}</p>
                  </div>
                </router-link>
              </div>
              <div class="side-menu-faqs-item" v-if="!!menuItems[4]">
                <router-link :to="{ name: 'reserved-area-personal-info', params:{category:menuItems[4].slug}}" class="side-menu-faqs-btn d-flex justify-content-between align-items-center" :class="[ this.$root.settingsLinkActive ? 'active' : '' ]">
                  <div class="d-flex justify-content-start align-items-center">
                    <div class="side-menu-faqs-btn-image">
                      <settingsIcon/>
                    </div>
                    <p>{{ menuItems[4].title }}</p>
                  </div>
                </router-link>
              </div>
            </div>
          </nav>
        </div>
        <div>
          <workoutPlans class="d-none d-xl-block" />
          <div class="payment-images">
            <img class="reduniq" src="@/assets/img/reduniq.webp" alt="">
            <div class=" d-flex justify-content-center align-items-center">
              <img class="visa" src="@/assets/img/vbyvisa_blu.png" alt="">
              <img class="mastercard" src="@/assets/img/mastercard securecode.gif" alt="">
            </div>
          </div>
        </div>
      </div>
  </div>
</template>

<script>
import LogoBoxSVG from "@/components/svg/logo-black";
import chevronDown from "@/components/svg/chevron-down.vue";
import plus from "@/components/svg/plus.vue";
import workout from "@/components/svg/workout.vue";
import calendar from "@/components/svg/calendar.vue";
import progressIcon from "@/components/svg/progress.vue";
import flag from "@/components/svg/flag.vue";
import settingsIcon from "@/components/svg/settings-icon.vue";
import workoutPlans from "@/components/workout-plans.vue";
import axios from "axios";

export default {
  name: "side-menu",
  data() {
    return {
      errors: [],
      lang: this.$root.siteLang,
      menuItems: [],
    }
  },
  components : {
    LogoBoxSVG,
    chevronDown,
    plus,
    workout,
    calendar,
    progressIcon,
    flag,
    settingsIcon,
    workoutPlans
  },
  methods: {
    toggleSideMenu (e) {
      let clickedItem = e.currentTarget.closest('.side-menu-faqs-item');
      let items = Array.from(document.querySelectorAll('.side-menu-faqs-item'));

      if ( clickedItem.classList.contains('active') ) {
        clickedItem.classList.remove('active');
      } else {
        items.forEach(node => {
          node.classList.remove('active');
        });

        clickedItem.classList.add('active');
      }
    }
  },
  mounted() {
    axios
        .get(process.env.VUE_APP_API_URL + "menu?lang=" + this.lang)
        .then((response) => {
          this.menuItems = response.data;
        })
        .catch((e) => {
          this.errors.push(e);
        });
  }
}
</script>