<template>
  <div class="notification-menu-wrapper" :class="{open: this.$root.showNotifications }">
    <div class="container">
      <div class="d-flex justify-content-between align-items-center notification-menu-header">
        <button type="button" class="notification-menu-close d-xl-none" @click="closeNotificationMenu">
          <chevronLeft/>
        </button>
        <p class="section-title">NOTIFICAÇÕES</p>
        <!--<button type="button" class="notification-menu-trash">
          <trash/>
        </button>-->
      </div>
      <div class="notification-menu-list">
        <!--<div class="notification-menu-item new-item">
          <a href="" class="notification-menu-item-link d-flex justify-content-start align-items-start">
            <div class="notification-menu-item-image-wrapper">
              <div class="notification-menu-item-image">
                <img src="https://via.placeholder.com/30" alt="">
              </div>
            </div>
            <div class="notification-menu-item-text">
              <p class="notification-menu-item-title">Treino Johanna Cunningham </p>
              <div class="notification-menu-item-time d-flex justify-content-start align-items-center">
                <div class="notification-menu-item-time-icon">
                  <bell/>
                </div>
                <p class="notification-menu-item-time-title">Hoje</p>
              </div>
            </div>
          </a>
        </div>

        <div class="notification-menu-item">
          <a href="" class="notification-menu-item-link d-flex justify-content-start align-items-start">
            <div class="notification-menu-item-image-wrapper">
              <div class="notification-menu-item-image">
                <notes/>
              </div>
            </div>
            <div class="notification-menu-item-text">
              <p class="notification-menu-item-title">Lorem ipsum dolor sit amet, consectetur adipiscing elit. </p>
              <div class="notification-menu-item-time d-flex justify-content-start align-items-center">
                <div class="notification-menu-item-time-icon">
                  <bell/>
                </div>
                <p class="notification-menu-item-time-title">Mês Passado</p>
              </div>
            </div>
          </a>
        </div>
        -->
        <div class="notification-menu-item" v-for="(item,index) in notifications" :key="index">
          <router-link :to="{ name: 'workout-detail', params:{id:$root.getLink(item)}}" class="notification-menu-item-link d-flex justify-content-start align-items-start">
            <div class="notification-menu-item-image-wrapper">
              <div class="notification-menu-item-image">
                <flag/>
              </div>
            </div>
            <div class="notification-menu-item-text">
              <p class="notification-menu-item-title">{{ item.title }}</p>
              <div class="notification-menu-item-time d-flex justify-content-start align-items-center">
                <div class="notification-menu-item-time-icon">
                  <bell/>
                </div>
                <p class="notification-menu-item-time-title">Hoje</p>
              </div>
            </div>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import chevronLeft from "@/components/svg/chevron-left.vue";
import trash from "@/components/svg/trash.vue";
import bell from "@/components/svg/bell.vue";
import flag from "@/components/svg/flag.vue";
import notes from "@/components/svg/notes.vue";
import axios from "axios";

export default {
  name: "notification-menu",
  data() {
    return {
      errors: [],
      notifications: [],
    }
  },
  components: {
    chevronLeft,
    trash,
    bell,
    flag,
    notes
  },
  methods: {
    closeNotificationMenu() {
      let element = Array.from(document.querySelectorAll('.notification-menu-wrapper'));
      //let html = document.getElementsByTagName("html")[0];
      //let body = document.getElementsByTagName("body")[0];

      element.forEach(node => {
        if ( node.classList.contains("open") ) {
          //let style = window.getComputedStyle(body);
          //let scrolledValue = parseInt(style.getPropertyValue('top').slice(0, -2)) * -1;

          node.classList.remove("open");
          //html.classList.remove("no-scroll");

          //body.style.top = "auto";

          //window.scrollTo(0, scrolledValue);
        }
      });
    }
  },
  mounted() {
    let authKey = window.$cookies.get("authKey");

    axios
        .get(process.env.VUE_APP_API_URL + 'notification',{
          headers: {
            Authorization: 'Bearer ' + authKey
          }
        })
        .then((response) => {
          this.notifications = response.data.model;
        })
        .catch((e) => {
          this.errors.push(e);
        }).finally((e) => {
          ( this.notifications != undefined && this.notifications.length > 0 ) ? this.$root.showNotifications = true : this.$root.showNotifications = false;
        });
  }
}
</script>