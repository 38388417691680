<template>
  <header :class="[ this.$root.headerFixed ? 'fixed' : '']">
    <div class="menu-top d-xl-none">
      <div class="container">
        <div class="d-flex justify-content-between align-items-center justify-content-xl-center">
          <div class="flex-1 d-xl-none">
            <button type="button" class="menu-mobile-btn" @click="toggleMenu()">
              <div class="menu-icon">
                <menuIcon/>
              </div>
              <div class="close-icon">
                <plus/>
              </div>
            </button>
          </div>
          <router-link to="/dashboard" class="menu-top-logo">
            <LogoBoxSVG/>
          </router-link>
          <div class="flex-1 d-flex justify-content-end align-items-center d-xl-none menu-top-buttons">
            <button type="button" class="menu-top-button-item favorites-btn" @click="toggleFavoritesMenu">
              <bookmark/>
            </button>
            <button type="button" class="menu-top-button-item notifications-btn" @click="toggleNotificationMenu">
              <bell/>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="header-desktop d-none d-xl-flex justify-content-between" :class="[ this.$root.headerFixed ? 'transparent white-content' : 'grey-bg']">
      <div class="header-page-heading d-flex justify-content-start align-items-center">
        <p class="header-page-title" v-if="this.$root.hasHeaderTitle">{{ this.$root.pageTitle }}</p>
      </div>
      <div class="page-search d-flex align-items-center" v-if="this.$root.hasSearch">
        <form>
          <FormulateInput type="text" v-model="searchBy" name="search" placeholder="Pesquisar"/>
          <button type="submit" @click.stop.prevent="submit()" class="page-search-icon">
            <searchIcon/>
          </button>
        </form>
      </div>
      <div class="user-menu-wrapper d-flex align-items-center">
        <div class="user-menu-btns d-flex justify-content-end align-items-center">
          <div class="menu-top-button-item-wrapper">
            <button type="button" class="menu-top-button-item favorites-btn" @click="toggleFavoritesMenu">
              <bookmark/>
            </button>
            <favoritesMenu/>
          </div>
          <div class="menu-top-button-item-wrapper">
            <button type="button" class="menu-top-button-item notifications-btn" @click="toggleNotificationMenu">
              <bell/>
            </button>
            <notificationMenu/>
          </div>
        </div>
        <div class="user-presentation-wrapper">
          <div class="container">
            <div class="user-presentation d-flex justify-content-start align-items-start">
              <div class="user-presentation-img">
                <img :src="this.$root.userInfo.photo ? this.$root.userInfo.photo : require('@/assets/img/user.jpg')" alt="">
              </div>
              <div class="user-presentation-content">
                <p class="user-presentation-title">Olá {{ this.$root.userInfo.name }}!</p>
                <div class="user-presentation-info d-flex justify-content-start align-items-center">
                  <p v-if="this.$root.userInfo.premium_until != null">Plano Premium</p>
                  <p v-else>Plano Grátis</p>
                  <p><span>{{ this.$root.userInfo.credits }}</span> Créditos</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <button type="button" class="logout-btn" @click="this.$root.logoutHandler">
          <logout/>
        </button>
      </div>
    </div>
    <div class="search-mobile d-xl-none" v-if="this.$root.hasSubHeader" :class="{open: this.$root.showMobileSearch }">
      <div class="container">
        <div class="search-mobile-heading d-flex justify-content-center align-items-center">
          <button v-if="!this.$root.reservedArea" type="button" class="search-mobile-heading-back d-flex justify-content-center align-items-center">
            <chevronLeft/>
          </button>
          <button v-if="this.$root.reservedArea" type="button" class="search-mobile-heading-btn d-flex justify-content-center align-items-center" @click="openReservedAreaMenu">
            <settingsIcon/>
          </button>
          <p class="search-mobile-title" v-if="this.$root.hasHeaderTitle">{{ this.$root.pageTitle }}</p>
          <button type="button" class="search-mobile-btn d-flex justify-content-center align-items-center" @click="toggleMobileSearch()" v-if="this.$root.hasSearch">
            <search/>
          </button>
        </div>
        <div class="page-search page-search-mobile" v-if="this.$root.hasSearch">
          <form>
            <FormulateInput type="text" v-model="searchBy" name="search" placeholder="Pesquisar"/>
            <button type="submit" @click.stop.prevent="submit()" class="page-search-icon">
              <searchIcon/>
            </button>
            <button type="button" class="page-search-mobile-close" @click="toggleMobileSearch()">
              <close/>
            </button>
          </form>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import LogoBoxSVG from "@/components/svg/logo-black";
import menuIcon from "@/components/svg/menu-icon.vue";
import bookmark from "@/components/svg/bookmark.vue";
import logout from "@/components/svg/logout.vue";
import searchIcon from "@/components/svg/search.vue";
import search from "@/components/svg/search.vue";
import plus from "@/components/svg/plus.vue";
import bell from "@/components/svg/bell.vue";
import close from "@/components/svg/close.vue";
import chevronLeft from "@/components/svg/chevron-left.vue";
import settingsIcon from "@/components/svg/settings-icon.vue";

import notificationMenu from "@/components/notification-menu.vue";
import favoritesMenu from "@/components/favorites-menu.vue";

export default {
  name: "boxheader",
  data() {
    return {
      lang: this.$root.siteLang,
      errors: [],
      searchBy: ''
    }
  },
  components: {
    LogoBoxSVG,
    menuIcon,
    bookmark,
    logout,
    searchIcon,
    plus,
    bell,
    search,
    close,
    chevronLeft,
    notificationMenu,
    favoritesMenu,
    settingsIcon,
  },
  methods: {
    toggleMenu() {
      let header = document.getElementsByTagName("header")[0];
      let element = document.getElementsByClassName("side-menu")[0];

      if (this.$root.showMenu) {
        header.classList.remove("menu-open");
        element.classList.remove("open");

        this.$root.showMenu = false;
      } else {
        header.classList.add("menu-open");
        element.classList.add("open");

        this.$root.showMenu = true;
      }
    },
    toggleMobileSearch() {
      let element = document.getElementsByClassName("search-mobile")[0];
      let boxContent = document.getElementsByClassName("box-page-content")[0];

      if (this.$root.showMobileSearch) {
        element.classList.remove("open");
        this.$root.showMobileSearch = false;

        if (boxContent.classList.contains('big-header-spacing')) {
          boxContent.classList.remove('search-open');
        }
      } else {
        this.$root.showMobileSearch = true;
        element.classList.add("open");

        if (boxContent.classList.contains('big-header-spacing')) {
          boxContent.classList.add('search-open');
        }
      }
    },
    toggleNotificationMenu() {
      let element = Array.from(document.querySelectorAll('.notification-menu-wrapper'));

      element.forEach(node => {
        if (node.classList.contains("open")) {
          this.$root.showNotifications = false;
          node.classList.remove("open");
        } else {
          this.$root.showNotifications = true;
          node.classList.add("open");
        }
      });
    },
    toggleFavoritesMenu(event) {
      let element = Array.from(document.querySelectorAll('.favorite-menu-wrapper'));

      element.forEach(node => {
        if (node.classList.contains("open")) {
          this.$root.showFavorites = false;
          node.classList.remove("open");
        } else {
          this.$root.showFavorites = true;
          node.classList.add("open");
        }
      });
    },
    openReservedAreaMenu() {
      let element = document.getElementsByClassName("reserved-area-menu-wrapper")[0];

      if (element.classList.contains("open")) {
        element.classList.remove("open");
      } else {
        element.classList.add("open");
      }
    },
    submit() {
      //if you want to send any data into server before redirection then you can do it here
      this.$router.push("/workout-search/" + this.searchBy);
    }
  }
}
</script>