<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="60" height="36" viewBox="0 0 60 36">
    <path fill="#A01E1D" d="M46.682 0v34.98h-6.197V0h6.197zM19.174 0v34.98h-6.195V0h6.195zm34.02 5.556v23.87h-6.197V5.556h6.197zM12.66 5.555v23.87H6.463V5.555h6.197zm46.999 5.965V23.46h-6.2V11.52h6.2zm-53.462-.064V23.39H0V11.456h6.197zm34.006 2.935v6.197H19.365V14.39h20.838z" transform="translate(-592 -933) translate(592 933.682)"/>
  </svg>
</template>

<script>
export default {
name: "weight-icon"
}
</script>