<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="19" viewBox="0 0 19 19">
    <path fill="#222221" d="M12 3l-.553.367L3 8.963V20c0 1.093.907 2 2 2h14c1.093 0 2-.907 2-2V8.963L12 3zm0 2.398l7 4.64V20H5v-9.963l7-4.639z" transform="translate(-29 -743) translate(0 705) translate(26.5 35)"/>
  </svg>
</template>

<script>
export default {
name: "home-icon"
}
</script>