<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="19" height="22" viewBox="0 0 19 22">
    <path fill="#222221" d="M15.428 18.77c-.008 0-.014-.005-.022-.005H9.274c-.007 0-.012.003-.019.005-1.452-.008-3-.018-3.952-.03.135-.267.256-.502.371-.723l.006-.014c1.066-2.066 1.552-3.01 1.552-6.18 0-2.29 1.053-4.06 2.89-4.857.206-.09.42-.165.636-.224l.003-.002.703-.197c.348-.05 1.028-.048 1.4.023l.608.17.02.006c.216.06.428.135.635.226 1.836.795 2.888 2.566 2.888 4.855 0 3.17.486 4.112 1.552 6.18.118.227.244.471.394.767h-3.533zm-4.55 1.713l-.019-.018c-.193-.198-.318-.442-.4-.7h3.33c-.084.258-.212.505-.419.718-.664.665-1.824.668-2.492 0zm.9-17.445c.221-.05.471-.05.691 0 .194.045.284.11.288.112v1.384c-.19-.02-.4-.031-.632-.031-.239 0-.452.01-.64.026l-.006-1.36c.015-.02.105-.087.3-.13zM3.896 17.1c-.209.405-.444.86-.742 1.465-.369.76-.053 1.678.701 2.046.26.128.281.139 4.83.157.185.416.447.799.776 1.128l.046.044c.706.684 1.633 1.06 2.617 1.06 1.006 0 1.951-.39 2.703-1.146.31-.319.556-.686.734-1.084h4.155c.845 0 1.533-.688 1.533-1.534 0-.284-.078-.56-.18-.72-.289-.585-.519-1.032-.724-1.43-.995-1.929-1.33-2.579-1.33-5.262 0-3.125-1.49-5.564-4.09-6.69-.057-.024-.112-.048-.168-.07V3.15c0-1.014-.707-1.803-1.846-2.063-.513-.116-1.06-.116-1.576 0-1.138.26-1.845 1.05-1.845 2.063v1.913c-.056.022-.11.046-.166.07-2.6 1.128-4.092 3.567-4.092 6.69 0 2.684-.335 3.335-1.329 5.264l-.007.012z" transform="translate(-3 -1)"/>
  </svg>
</template>

<script>
export default {
name: "bell"
}
</script>