<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
    <path fill="#222221" d="M23.75 3.75c1.38 0 2.5 1.12 2.5 2.5v17.5c0 1.38-1.12 2.5-2.5 2.5H6.25c-1.38 0-2.5-1.12-2.5-2.5V6.25c0-1.38 1.12-2.5 2.5-2.5h17.5zm0 2.5H6.25v17.5h17.5V6.25zM9.75 10h.5c.552 0 1 .448 1 1v6.75c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1V11c0-.552.448-1 1-1zm5 5h.5c.552 0 1 .448 1 1v1.75c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1V16c0-.552.448-1 1-1zm5-2.5h.5c.552 0 1 .448 1 1v4.25c0 .552-.448 1-1 1h-.5c-.552 0-1-.448-1-1V13.5c0-.552.448-1 1-1z" transform="translate(-23 -508) translate(20 505)"/>
  </svg>
</template>

<script>
  export default {
    name: 'boxprogress'
  }
</script>
