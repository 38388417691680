<template>
  <div class="box-bottom-menu">
    <div class="container">
      <div class="d-flex justify-content-center align-items-end">
        <router-link to="/dashboard" class="box-bottom-menu-btn">
          <div class="box-bottom-menu-btn-icon">
            <homeIcon/>
          </div>
          <p>Ínicio</p>
        </router-link>
        <router-link to="/calendar-list" class="box-bottom-menu-btn">
          <div class="box-bottom-menu-btn-icon">
            <calendarIcon/>
          </div>
          <p>Calendário</p>
        </router-link>
        <router-link to="/workout-list" class="box-bottom-menu-special-btn">
          <div class="box-bottom-menu-btn-icon">
            <notesIcon/>
          </div>
          <p>Treinar</p>
        </router-link>
        <router-link to="/challenges-list" class="box-bottom-menu-btn">
          <div class="box-bottom-menu-btn-icon">
            <flagIcon/>
          </div>
          <p>Desafios</p>
        </router-link>
        <router-link to="/progress-achievements" class="box-bottom-menu-btn">
          <div class="box-bottom-menu-btn-icon">
            <progressIcon/>
          </div>
          <p>Progress</p>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>

import homeIcon from "@/components/svg/home-icon.vue";
import calendarIcon from "@/components/svg/calendar.vue";
import flagIcon from "@/components/svg/flag.vue";
import progressIcon from "@/components/svg/progress.vue";
import notesIcon from "@/components/svg/notes.vue";

export default {
  name: "box-bottom-menu",
  components: {
    homeIcon,
    calendarIcon,
    flagIcon,
    progressIcon,
    notesIcon
  }
}
</script>